
import React, { useState, useEffect} from 'react';
import * as signalR from '@microsoft/signalr';
import backgroundImage from './images/recipeguru.png'; // Import the image
import { v4 as uuidv4 } from 'uuid';
import QRCode from 'qrcode.react';
import './Viewer.css'; // Import the App.css file
import UserProfile from './Profile';
import { getCookie, setCookie } from './Components/cookies.js';
import AudioRenderer from './Components/AudioRender.js';
import AudioTranscript from './Components/AudioTranscript.js';
import ReactDOM from 'react-dom';
import AskQuestion from './Components/Assistant.js';
import Footer from './Components/Footer';

function Viewer({ API_URL }) {
  const [log, setLog] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [regConnection, setRegConnection] = useState(null);
  const [recConnection, setRecConnection] = useState(null);
  const [isActivation, setIsActivation] = useState(false);
  // eslint-disable-next-line
  const [isDebug, setIsDebug] = useState(false);
  const [deviceName, setDeviceName] = useState(getCookie('deviceName'));
  const [deviceId, setDeviceId] = useState(getCookie('deviceId')); // Add this line
  const [connectionStatus, setConnectionStatus] = useState('Disconnected'); // Add this line
  const [currentRecipe, setCurrentRecipe] = useState(null);
  const [currentFrame, setCurrentFrame] = useState(null);

  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const [isSpinning, setIsSpinning] = useState(false);
  const [response, setResponse] = useState('');
  const [text, setText] = useState('');
  const [voice, setVoice] = useState('');
  // const [key, setKey] = useState(uuidv4());
  const [question, setQuestion] = useState('');
  const [questionResponse, setQuestionResponse] = useState('');
  const [ThreadID, setThreadID] = useState('');

  const setTextAndVoice = (newText, newVoice) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setVoice(newVoice);
      setText(newText);
      setIsSpinning(false);
      //setKey(uuidv4()); // update the key to trigger the AudioRenderer component
    });
  };
  const handleTranscription = (aiResponse) => {
    setIsSpinning(true);
    setResponse(aiResponse);
    console.log('AiResponse:', aiResponse);
    setQuestion(aiResponse);
    //setTextAndVoice(aiResponse, 'onyx')
    //console.log('Parent got Response:', aiResponse);
  };


  


/*const handleQuestionReply = useCallback((aiQuestionReply) => {
  console.log('RecipeGuru Replied:', aiQuestionReply.ThreadID, ' Message:', aiQuestionReply.Message);
  setThreadID(aiQuestionReply.ThreadID);
  setQuestionResponse(aiQuestionReply.Message);
  setTextAndVoice(aiQuestionReply.Message, 'onyx')
}, []);
*/



  
  const handleQuestionReply = (aiQuestionReply) => {
    console.log('RecipeGuru Replied:',aiQuestionReply.message);
    setThreadID(aiQuestionReply.threadID);
    setQuestionResponse(aiQuestionReply.message);
    let shortMessage = aiQuestionReply.message;
    if (shortMessage.length > 250) {
      const sentences = shortMessage.split('.');
      let message = '';
      for (let i = 0; i < sentences.length; i++) {
        if ((message + sentences[i]).length <= 250) {
          message += sentences[i] + '.';
        } else {
          break;
        }
      }
      shortMessage = message + ' The rest of the data is on screen.';
      /*
      const firstSentence = shortMessage.split('.')[0] + '.';
      shortMessage = firstSentence + ' The rest of the message is on screen.';
      */
    }
  
    setTextAndVoice(shortMessage, 'onyx')
  };


  useEffect(() => {

    if (question) { // to avoid running on initial render when question is ''
      console.log('Question: ',question, 'on thread: ', ThreadID);
      AskQuestion(ThreadID, question,handleQuestionReply);
    }
// eslint-disable-next-line
  }, [question]); // dependency array
  



  const authHubKey = process.env.REACT_APP_AUTH_KEY;
  const apiKey = process.env.REACT_APP_API_KEY; // Replace with your API key


  // This is the AudioRenderer component

  //const id = getCookie('deviceId');
  //const deviceName = getCookie('deviceName');

  //const API_URL = "https://recipeguru.azurewebsites.net";
  //API_URL = "https://localhost:7001";
  //const REG_URL = "http://172.31.2.1:3000";
  const REG_URL = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;

  useEffect(() => {    //Registration Hub Setup
    let id = deviceId;
    if (!id) {
      id = uuidv4();
      setCookie('deviceId', id, 90);
      setDeviceId(id);
      if (isDebug) setLog(prevLog => `${prevLog}\n${'device has a new id: ' + id}`);
    }

    if (deviceName) return;

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(API_URL + '/registerhub',
        {
          accessTokenFactory: () => authHubKey,
          transportType: signalR.HttpTransportType.LongPolling
        })
      .build()
    setRegConnection(connection);
    connection.on('DeviceRegistered', (key, deviceName) => {
      const message = `registration ${key} was received for ${deviceName}`;
      setDeviceName(deviceName);
      setCookie('deviceName', deviceName, 90);
      if (recConnection) {
        setLog(prevLog => `${prevLog}\nStarting RecipeHub`);
        startRecipeHub();
      }
      else {
        window.location.reload();
      }
      if (isDebug) setLog(prevLog => `${prevLog}\n${message}`);
    });
    connection.start()
      .then(() => {
        connection.invoke('JoinGroup', id)
          .then(() => {
            setIsActivation(true);
            if (isDebug) setLog(prevLog => `${prevLog}\nJoined as ${getCookie('deviceId')}`);
          })
          .catch(err => {
            if (isDebug) setLog(prevLog => `${prevLog}\n${err.toString()}`);
          });
      })
      .catch(err => {
        if (isDebug) setLog(prevLog => `${prevLog}\n${err.toString()}`);

      });
    // Add this to handle connection close
    connection.onclose(err => {
      setIsActivation(false);
      if (err) {
        if (isDebug) setLog(prevLog => `${prevLog}\n${err.toString()}`);
      }
    });
    return () => {
      connection.stop();
    };
    // eslint-disable-next-line
  }, []);


  useEffect(() => {    //Recipe Hub Setup
    //let id = getCookie('deviceId');

    if (!deviceName) {
      return;
    }
    // Create a new connection
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(API_URL + '/recipehub',
        {
          accessTokenFactory: () => apiKey,
          transportType: signalR.HttpTransportType.LongPolling

        })
      .build()
    setRecConnection(connection);
    // Define a method to handle the DisplayRecipe call
    connection.on('DisplayRecipe', (id) => {
      setCurrentFrame(null);
      fetchRecipe(id);
      const message = `Recipe with id ${id} was displayed`;
      if (isDebug) setLog(prevLog => `${prevLog}\n${message}`);
    });
    connection.on('DisplayFrame', (id) => {
      id = id.replaceAll('%2F', '/');
      const message = `Resource with src ${id} was displayed`;
      if (isDebug) setLog(prevLog => `${prevLog}\n${message}`);
      setCurrentRecipe(null);
      setCurrentFrame(id);
    });



    connection.start()
      .then(() => {
        setConnectionStatus('Connected'); // Add this line
        setLog(prevLog => `${prevLog}\nReconnected to /recipeHub`);
        connection.invoke('JoinGroup', deviceName)
          .then(() => setLog(prevLog => `${prevLog}/${deviceName}`))
          .then(() => {
            setConnectionStatus('Connected!');
            if (isDebug) setLog(prevLog => `${prevLog}/${deviceName}`);
          })
          .catch(err => {
            if (isDebug) setLog(prevLog => `${prevLog}\n${err.toString()}`);
          });

      })
      .catch(err => {
        setConnectionStatus('Disconnected');
        if (isDebug) setLog(prevLog => `${prevLog}\n${err.toString()}`);
      });




    // Add this to handle connection close
    connection.onclose(err => {
      setConnectionStatus('Disconnected');
      if (err) {
        if (isDebug) setLog(prevLog => `${prevLog}\n${err.toString()}`);
        setConnectionStatus('Disconnected');
      }
    });

    return () => {
      connection.stop();
    };
    // eslint-disable-next-line
  }, []);


  return (  // Main Viewer container switches between views
    <div className="Viewer" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className={`panel ${isPanelOpen ? 'open' : ''}`}>
        <p>Status: {connectionStatus} {deviceName && <>as <b>{deviceName}</b></>}</p>
        <p>Logged in: {isLoggedIn} <UserProfile /> </p>
        {deviceName && (
          <button className="forget-button" onClick={() => {

            if (recConnection) {
              recConnection.invoke('LeaveGroup', deviceName)
                .then(() => {
                  recConnection.stop()
                    .then(() => setLog(prevLog => `${prevLog}\nDisconnected from /recipeHub`))
                    .then(() => {
                      setCookie('deviceName', null);
                      setDeviceName(null);
                      if (regConnection) {
                        regConnection.start()
                          .then(() => setLog(prevLog => `${prevLog}\nConnected to /registrationHub`))
                          .catch(err => window.location.reload());
                      }
                      else { window.location.reload() }
                    })
                    .catch(err => setLog(prevLog => `${prevLog}\n${err.toString()}`));
                })
                .catch(err => setLog(prevLog => window.location.reload()));
            }


          }}>
            FORGET
          </button>
        )}<p> </p>
        {log}
      </div>
      <div className={`status-icon ${connectionStatus === 'Connected!' ? 'connected' : 'disconnected'}`} onClick={togglePanel}></div>

      {!deviceName ? (
        // The user is not logged in, render the login button and the QR code
        renderLoggedOut()
      ) : (
        <>

          {currentRecipe ? (
            <Recipe recipe={currentRecipe} onClose={() => setCurrentRecipe(null)} />
          ) : (
            <pre></pre>
          )}
          {currentFrame ? (
            <Frame src={currentFrame} onClose={() => setCurrentFrame(null)} />
          ) : (
            null
          )}

          <div>

           <AudioTranscript className="audioTranscript" isSpinning={isSpinning} onTranscription={handleTranscription} />
            <div style={{ display: 'block', left: '250px' }}>
              <h2>.</h2>
              <p>{response}</p>
            </div>
            <div>

              {<p>Reponse: {questionResponse}</p>}
            </div>
            <div /*style={{ display: 'none' }}*/>
              <AudioRenderer key='001' text={text} voice={voice} />
            </div>

          </div>
          <Footer selectedPage='E'/>
        </>
      )}
      
    </div>


  );


  /*
  recConnection.start()
          .then(() => setLog(prevLog => `${prevLog}\nReconnected to /recipeHub`))
          .catch(err => setLog(prevLog => `${prevLog}\n${err.toString()}`));
  */
  // An attempt to reconnect and re-enter the group
  function startRecipeHub() {
    //if(recConnection)
    //{
    recConnection.start()
      .then(() => {
        setConnectionStatus('Connected'); // Add this line
        setLog(prevLog => `${prevLog}\nReconnected to /recipeHub`);
        recConnection.invoke('JoinGroup', deviceName)
          //.then(() => setLog(prevLog => `${prevLog}\nJoined as ${deviceName}`))
          .then(() => {
            setConnectionStatus('Connected!');
            if (isDebug) setLog(prevLog => `${prevLog}/${deviceName}`);
          })
          .catch(err => {
            if (isDebug) setLog(prevLog => `${prevLog}\n${err.toString()}`);
          });

      })
      .catch(err => {
        setConnectionStatus('Disconnected');
        if (isDebug) setLog(prevLog => `${prevLog}\n${err.toString()}`);
      });

    // };
  }


  function renderLoggedOut() {   // Main registration content
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', transform: 'translateY(-150px)' }}>
        <div style={{ backgroundColor: '#f0f0f0', borderRadius: '10px', padding: '20px', width: '300px', textAlign: 'center' }}>
          <h2>Scan to connect</h2>

          {isActivation ? (
            <>
              <QRCode value={REG_URL + '/register?key=' + getCookie('deviceId')} size={128} />
              <p>Open your phone's camera and point it at the QR code</p>
            </>

          ) : (null)}

          <button
            onClick={() => setIsLoggedIn(true)}
            style={{
              backgroundColor: '#ffffff',
              color: 'black',
              border: 'none',
              borderRadius: '2px',
              padding: '10px 20px',
              fontSize: '14px',
              fontWeight: 'bold',
              cursor: 'pointer',
              marginTop: '20px',
            }}
          >

            Login Bypass
          </button>
        </div>
      </div>
    );
  }


  function togglePanel() {    //Panel helper 
    setIsPanelOpen(prevIsPanelOpen => !prevIsPanelOpen);
  }



  function Recipe({ recipe, onClose }) {   // This is the recipe Panel
    const placeholderImage = 'https://demofree.sirv.com/nope-not-here.jpg';
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div style={{ position: 'relative', display: 'flex', border: '1px solid black', padding: '20px', borderRadius: '10px', width: '80%', background: '#fff', boxShadow: '-7px 7px 10px rgba(0, 0, 0, 0.6)' }}>
          <button onClick={onClose} style={{ position: 'absolute', top: '10px', right: '10px' }}>X</button>
          <div style={{ flex: '1', paddingRight: '20px', borderRight: '1px solid black' }}>
            <h1>{recipe.name}</h1>
            <p>{recipe.description}</p>
            <h2>Ingredients (Serves {recipe.serves})</h2>
            <ul>
              {recipe.ingredients.map((ingredient, index) => (
                <li key={index}>{ingredient.name}: {ingredient.quantity}</li>
              ))}
            </ul>
          </div>
          <div style={{ flex: '1', paddingLeft: '20px' }}>
            <h2>Steps</h2>
            <ol>
              {recipe.instructions.map((instruction, index) => (
                <li key={index}>{instruction.description}</li>
              ))}
            </ol>
            <h2>Images</h2>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {[0, 1, 2].map(index => (
                <img key={index} src={recipe.images[index] || placeholderImage} alt="" style={{ width: '30%', height: 'auto' }} />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }


  function Frame({ src, onClose }) {  //This is the iFrame Panel
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div style={{ position: 'relative', border: '1px solid black', padding: '20px', borderRadius: '10px', width: '80%', background: '#fff', boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.25)' }}>
          <button onClick={onClose} style={{ position: 'absolute', top: '10px', right: '10px' }}>X</button>
          <iframe src={src} style={{ width: '100%', height: '80vh' }} title="Frame" />
        </div>
      </div>
    );
  }

  function fetchRecipe(id) {   // Fetches a recipe from Cosmos from RecipeID
    const message = `Recipe with id ${id} is being fetched`;
    setLog(prevLog => `${prevLog}\n${message}`);
    fetch(API_URL + `/api/Recipes/${id}`, {
      headers: { 'Authorization': `${apiKey}` }
    })
      .then(response => { return response.json() })
      .then(recipe => {
        setLog(prevLog => `${prevLog}\n${recipe.description} was found`);
        setCurrentRecipe(recipe)
      })
      .catch(err => setLog(prevLog => `${prevLog}\n${err.toString()}`)); // Log any errors
  }


}
export default Viewer;
