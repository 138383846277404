import React, { useEffect, useRef, useState } from 'react';
import KITTAudioVisualizer from './Kitt2';

const AudioRenderer = ({ text, voice }) => {
  const audioRef = useRef();
  const apiKey = process.env.REACT_APP_API_KEY;
  const API_URL = process.env.REACT_APP_API_URL;
  const [audioUrl, setAudioUrl] = useState(null);

  useEffect(() => {
    const audioElement = audioRef.current; // capture the current value of audioRef.current

    fetch(API_URL + '/api/Speech', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + apiKey
        },
        body: JSON.stringify({ text, voice })
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('API request failed');
        }
        return response.blob();
      })
      .then(blob => {
        const url = URL.createObjectURL(blob);
        setAudioUrl(url);
        audioElement.src = url;
        audioElement.play();
      })
      .catch(error => {
        console.error('Error:', error);
        // Optionally, set audioElement.src to a default audio file or message
        // audioElement.src = 'default-audio-file-url';
      });
      
      // Use audioElement in the cleanup function
      return () => {
        if (audioElement) {
          audioElement.pause();
          audioElement.src = '';
        }
      };

  }, [text, voice, API_URL, apiKey]); // add voice to the dependency array


  return (
    <>
    
    
      
      {audioUrl && <KITTAudioVisualizer audioRef={audioRef} audioUrl={audioUrl} />}
      <div style={{display: 'none'}} >
      <audio ref={audioRef} controls >
        Your browser does not support the audio element.
      </audio>
      </div>
    </>

  );
};

export default AudioRenderer;
