import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function SignOut() {
  const navigate = useNavigate();

  useEffect(() => {
    fetch('/.auth/logout')
      .then(() => navigate('/'))
      .catch((error) => console.error('Error during sign out:', error));
  }, [navigate]);

  return null;
}

export default SignOut;