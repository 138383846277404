//import { useEffect } from 'react';
//import { useIsAuthenticated, useMsal, useAccount } from '@azure/msal-react';

/*
async function GetUserAttributes(API_URL, ProfileAttributes) {
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const account = useAccount(accounts[0] || {});
    //const navigate = useNavigate();


    useEffect(() => {
        const fetchProfile = async () => {
            if (isAuthenticated) {
                try {
                    const profile = await GetProfile(instance, account, API_URL);
                    console.log('Subscriber level:', profile);
                    ProfileAttributes.setSubscriberLevel(profile);
                } catch (error) {
                    console.error('Error fetching profile:', error);
                    // Handle the error appropriately
                }
            }
        };

        fetchProfile();
    }, [account, isAuthenticated, instance, API_URL]);
}
*/
async function GetProfile(instance, account, API_URL) {

    //const account = instance.getActiveAccount();


    if (!account) {
        throw new Error('No active account');
    }

    try {
        const response = await instance.acquireTokenSilent({
            scopes: ['da164f41-d248-48b5-a2bc-0c4470623fb9'], // Replace with your API scopes
            account: account,
        });

        const profileResponse = await fetch(API_URL + `/api/Profile`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${response.accessToken}`
            },
        });

        if (!profileResponse.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await profileResponse.json();
        const subscriberLevel = data.subscriberLevel;
        console.log('Subscriber level:', subscriberLevel);
        return subscriberLevel;

    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
        // Handle the error appropriately
        // Depending on your use case, you might want to return a default value or rethrow the error
    }
}



export default GetProfile;