import React, { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal, useAccount } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import GetProfile from './Components/ProfileAttributes'
import './VoiceChat.css';
import Navbar from './Components/Navbar';
import SearchBar from './Components/Search';
import Footer from './Components/Footer';
import AudioRenderer from './Components/AudioRender.js';

function VoiceChat({ API_URL }) {
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const account = useAccount(accounts[0] || {});
    const navigate = useNavigate();
    const [subscriberLevel, setSubscriberLevel] = useState(0);
    const [isBouncing, setIsBouncing] = useState(false);

    // Search
    const [searchOption, setSearchOption] = useState('type-users');
    // Text to Speech
    const [TextToSpeech, Say] = useState(null);
    
 
    const SearchSubmit = (searchText) => {
        console.log(searchText.target.elements);
        Say("searching " + searchOption + ' for ' + searchText.target.elements[0].value);
        console.log(searchText.target.elements[0].value);
    }

    const HandleSearchOption = (searchOption) => {
        console.log(searchOption.target);
        setSearchOption(searchOption.target.value);
    }


    useEffect(() => {
        const fetchProfile = async () => {
            if (isAuthenticated) {
                try {
                    const profile = await GetProfile(instance, account, API_URL);
                    setSubscriberLevel(profile);
                    if (profile < 1) {
                        navigate('/Subscribe');
                      }
                } catch (error) {
                    console.error('Error fetching profile:', error);
                    // Handle the error appropriately
                }
            }
        };

        fetchProfile();
    }, [account, isAuthenticated, instance, API_URL,navigate]);


    return (
        <div className="VoiceChat">

            <Navbar />
            <div className="SoundContainer">
                
             </div>
            <div className="ListeningCircle" onClick={() => setIsBouncing(!isBouncing)}>
                 <div className="AudioRender">
                     <AudioRenderer key='001' text={TextToSpeech} voice={'Nova'} />
                 </div>
            </div>                
                     
 
            

            <div className="Processing">
               <div className="Balls">
                {Array.from({ length: 4 }, (_, i) => (
                    <div key={i} className={`circle ${isBouncing ? 'bounce' : ''}`}></div>
                ))}
                </div>
                <div className={`StartTalking ${isBouncing ? 'hiddenText' : ''}`}>
                    Start Talking
                </div>
                <div className="SearchBar">
                    
                </div>
            </div>
            

            <SearchBar onSearchSubmit={SearchSubmit} onOptionChange={HandleSearchOption} enableAI={!!account && subscriberLevel === 1} />

            <Footer selectedPage='E' />
        </div>
    );
}


export default VoiceChat;