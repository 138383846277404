import React, { useEffect, useState } from 'react';
import { useIsAuthenticated,useMsal, useAccount } from '@azure/msal-react';
//import { useNavigate } from 'react-router-dom';
import GetProfile from './Components/ProfileAttributes'
import './UserLandingPage.css';
import Navbar from './Components/Navbar';
import SearchBar from './Components/Search';
import Footer from './Components/Footer';

function UserLandingPage({ API_URL }) {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(accounts[0] || {});
  //const navigate = useNavigate();
  const [subscriberLevel, setSubscriberLevel] = useState(0);


  useEffect(() => {
    const fetchProfile = async () => {
      if (isAuthenticated) {
        try {
          const profile = await GetProfile(instance, account, API_URL);
          setSubscriberLevel(profile);
        } catch (error) {
          console.error('Error fetching profile:', error);
          // Handle the error appropriately
        }
      }
    };
  
    fetchProfile();
  }, [account, isAuthenticated, instance, API_URL]);




  return (
    <div className="UserLandingPage">
     
      <Navbar />
      <SearchBar enableAI={!!account && subscriberLevel===1}/>
      <Footer selectedPage='A' subscriberLevel={subscriberLevel}/>
    </div>
  );
}


export default UserLandingPage;