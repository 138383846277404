
import './CookiePolicy.css';
import Navbar from './Components/Navbar';


function CookiePolicy() {




  return (
    <div className="CookiePolicy">
     
      <Navbar />
      
      <>
    <div id="__next" class="cookiepolicy">

    <main>
        <div class="terms container mt-5 pt-5">
            <h1>Cookie Policy</h1>
            <h4 class="wp-block-heading">
                <strong>Information about our use of cookies</strong>
            </h4>
            <p>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site.</p>
            <p>
                This Cookie Policy should be read in conjunction with our General Privacy Policy (<a href="https://alpha.recipeguru.ai/general-privacy-policy/">https://alpha.recipeguru.ai/general-privacy-policy/</a>
                ).
            </p>
            <p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer’s hard drive.</p>
            <p>We use the following cookies:</p>
            <ul>
                <li>
                    <strong>Strictly necessary cookies.</strong>
                    These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services.
                </li>
                <li>
                    <strong>Analytical/performance cookies.</strong>
                    They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.
                </li>
                <li>
                    <strong>Functionality cookies.</strong>
                    These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
                </li>
                <li>
                    <strong>Targeting cookies.</strong>
                    These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.
                </li>
            </ul>

            <figure class="wp-block-table">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <strong>Cookie</strong>
                            </td>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Purpose</strong>
                            </td>
                            <td>
                                <strong>More information</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Google Analytics</td>
                            <td>_ga _git _gad</td>
                            <td>These cookies are used to collect information about how visitors use our website. We use the information to compile reports and to help us improve the website. The cookies collect information in an anonymous form, including the number of visitors to the website and blog, where visitors have come to the website from and the pages they visited.</td>
                            <td>
                                <a href="https://support.google.com/analytics/answer/6004245">Read Google’s overview of privacy and safeguarding data</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </figure>

            <figure class="wp-block-table">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <strong>Cookie</strong>
                            </td>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Purpose</strong>
                            </td>
                            <td>
                                <strong>More information</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Anonymous Session</td>
                            <td>.ASPXANONYMOUS</td>
                            <td>This cookie helps maintain your visit to our website anonymously (before you may log in to a secure area).</td>
                            <td>
                                <a href="https://cookiepedia.co.uk/cookies/.ASPXANONYMOUS">General cookie information</a>
                            </td>
                        </tr>
                        <tr>
                            <td>Anti-forgery Token</td>
                            <td>__RequestVerificationToken_Lw__</td>
                            <td>Cookie used to prevent cross site request forgery.</td>
                            <td>
                                <a href="http://blog.stevensanderson.com/2008/09/01/prevent-cross-site-request-forgery-csrf-using-aspnet-mvcs-antiforgerytoken-helper/">General cookie information</a>
                            </td>
                        </tr>
                        <tr>
                            <td>Google Analytics</td>
                            <td>_utma</td>
                            <td>Stores the amount of visits (for each visitor), the time of the first visit, the previous visit, and the current visit</td>
                            <td>
                                <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=en">Google Analytics information</a>
                            </td>
                        </tr>
                        <tr>
                            <td>_utmb _utmc</td>
                            <td>
                                Used to check <em>approximately</em>
                                how fast a website visitor leaves: when a visit starts, and approximately ends (‘c’ expires quickly).
                            </td>
                        </tr>
                        <tr>
                            <td>_utmt</td>
                            <td>Used to throttle request rate.</td>
                        </tr>
                        <tr>
                            <td>_utmz</td>
                            <td>Records whether the visitor came from a search engine (and if so, the search keyword used), a link, or from no previous page (e.g. a bookmark).</td>
                        </tr>
                        <tr>
                            <td>Google Analytics</td>
                            <td>_ga _gid</td>
                            <td>These cookies are used to collect information about how visitors use our website. We use the information to compile reports and to help us improve the website. The cookies collect information in an anonymous form, including the number of visitors to the website and blog, where visitors have come to the website from and the pages they visited.</td>
                            <td>
                                <a href="https://support.google.com/analytics/answer/6004245">Read Google’s overview of privacy and safeguarding data</a>
                            </td>
                        </tr>
                        <tr>
                            <td>Session ID</td>
                            <td>ASP.NET_SessionId</td>
                            <td>General purpose platform session cookie. Used to maintain an anonymised user session by the server. This cookie helps keep your browsing session private. Deleted once browser closed.</td>
                            <td>
                                <a href="https://cookiepedia.co.uk/cookies/ASP.NET_SessionId">General cookie information</a>
                            </td>
                        </tr>
                        <tr>
                            <td>Currency</td>
                            <td>currencyData</td>
                            <td>Determines currency based on country IP address location – only holds currency value, e.g. GBP. Deleted once browser closed.</td>
                            <td>Custom cookie</td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <p>Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.</p>
            <p>You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site.</p>
            <p>Except for essential cookies, all cookies will expire after 2 Years.</p>
            <p>
                <strong>How do I change my cookie settings?</strong>
            </p>
            <p>
                Most web browsers allow some control of most cookies through the browser settings. To find out more about cookies, including how to see what cookies have been set, visit

                <a href="http://www.aboutcookies.org">http://www.aboutcookies.org</a>
                or www.allaboutcookies.org.
            </p>
            <p>Find out how to manage cookies on popular browsers:</p>
            <ul>
                <li>
                    <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=en">Google Chrome</a>
                </li>
                <li>
                    <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">Microsoft Edge</a>
                </li>
                <li>
                    <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Mozilla Firefox</a>
                </li>
                <li>
                    <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">Microsoft Internet Explorer</a>
                </li>
                <li>
                    <a href="https://www.opera.com/help/tutorials/security/privacy/">Opera</a>
                </li>
                <li>
                    <a href="https://support.apple.com/kb/ph21411?locale=en_US">Apple Safari</a>
                </li>
            </ul>
            <p>To find information relating to other browsers, visit the browser developer’s website.</p>
            <p>
                To opt out of being tracked by Google Analytics across all websites, visit
                
                <a href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a>
                .
            </p>
            <p>
                If you wish to change your cookie choices after making your initial choice when accessing one of our websites, please contact by writing to us at Recipe Guru Ltd, 71-75 Shelton Street, Covent Garden, London, United Kingdom WC2H 9JQ, or by emailing <a href="mailto:enquiries@recipeguru.ai">enquiries@recipeguru.ai</a>
                .
            </p>
        </div>
    </main>
    <footer class="container-fluid">
        
        <div class="footer-bottom">
            <div class="copyright-txt">
                <ul class="botom-links d-flex justify-content-center gap-4">
                    <li>
                        <a href="/terms-and-conditions">Terms &amp;Conditions</a>
                    </li>
                    <li>
                        <a href="general-privacy-policy.html" target="_blank" rel="noreferrer">Privacy Policy</a>
                    </li>
                    <li>
                        <a href="cookie-policy" target="_blank" rel="noreferrer">Cookie Policy</a>
                    </li>
                </ul>
                <div>© 2024 Recipe Guru Ltd. All rights reserved.</div>
            </div>
        </div>
    </footer>
</div>
</>
     
    </div>
  );
}

export default CookiePolicy;