//import React, { useState } frm 'react';
import React, {  } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {   MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import {  PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { msalConfig } from './Components/msalConfig';
import Viewer from './Viewer'; // Import the Viewer component
import PrivacyPolicy from './PrivacyPolicy'; // Import the Viewer component
import CookiePolicy from './CookiePolicy'; // Import the Viewer component
import Homepage from './Homepage'; // Import the HomePage component
import Register from './Register'; // Import the HomePage component
import SignOut from './SignOut'; // Import the HomePage component
import RecipeBook from './RecipeBook'; // Import the HomePage component
import RecipeBookAlpha from './RecipeBookAlpha'; // Import the HomePage component
import UserLandingPage from './UserLandingPage'; // Import the HomePage component
import VoiceChat from './VoiceChat'; // Import the HomePage component
import Subscribe from './Subscribe'; // Import the HomePage component
import KittTest from './KittTest2'; // Import the HomePage component




function App() {
  //const [isLoggedIn, setIsLoggedIn] = useState(false);

  //const API_URL = "https://localhost:7001";
  //const REG_URL = "http://172.31.2.1:5225";

  //const API_URL = "https://recipeguruapi-uks.azurewebsites.net";
  //const REG_URL = "https://recipeguruapi-uks.azurewebsites.net";
  const API_URL = process.env.REACT_APP_API_URL;
  const REG_URL = process.env.REACT_APP_API_URL;
  //const REG_URL = "https://localhost:7001";

 const msalInstance = new PublicClientApplication(msalConfig);
 


 

  return (

    <MsalProvider instance={msalInstance}>
    <Router>
      <Routes>
        <Route path="/viewer" element={<Viewer API_URL={API_URL} />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/CookiePolicy" element={<CookiePolicy />} />
        <Route path="/RecipeBook" element={
          <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
              <RecipeBook API_URL={API_URL} />
          </MsalAuthenticationTemplate>
        } />

         <Route path="/RecipeBookAlpha" element={
          <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
              <RecipeBookAlpha API_URL={API_URL} />
          </MsalAuthenticationTemplate>
        } />

        <Route path="/signout" element={ <SignOut  />} />
        <Route path="/register" element={
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
              <Register API_URL={API_URL} REG_URL={REG_URL}/>
       </MsalAuthenticationTemplate>
                } />
        <Route path="/UserLandingPage" element={
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
              <UserLandingPage API_URL={API_URL} REG_URL={REG_URL}/>
       </MsalAuthenticationTemplate>
                } />
        <Route path="/VoiceChat" element={
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
              <VoiceChat API_URL={API_URL} REG_URL={REG_URL}/>
       </MsalAuthenticationTemplate>
                } />
        <Route path="/Subscribe" element={
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
              <Subscribe API_URL={API_URL} REG_URL={REG_URL}/>
       </MsalAuthenticationTemplate>
                } />
        <Route path="/" element={<Homepage instance={msalInstance}/>} />
        <Route path="/KittTest" element={<KittTest />} />
      </Routes>
    </Router>
    </MsalProvider>
  );
}


export default App;