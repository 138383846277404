import React, { useState } from 'react';
import './Search.css'; // Ensure this file contains the CSS part.

const SearchForm = ({ onSearchSubmit, onOptionChange, enableAI }) => {
  const [isFocused, setIsFocused] = useState(false);
 

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSearchSubmit) onSearchSubmit(e);
  };

  const handleOptionChange = (e) => {
    if (onOptionChange) onOptionChange(e);
  };

  return (
    <div className="SearchBar">
    <form className={`search-form ${isFocused ? 'focus' : ''}`} onSubmit={handleSubmit}>
      <input
        type="search"
        placeholder="Search"
        className="search-input"
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <button type="submit" className="search-button">
        <svg className="submit-button">
          <use xlinkHref="#search" />
        </svg>
      </button>
      <div className="search-option">
        <div>
          <input
            name="type"
            type="radio"
            value="type-users"
            id="type-users"
            defaultChecked
            onChange={handleOptionChange}
          />
          <label htmlFor="type-users">
            <svg className="edit-pen-title">
              <use xlinkHref="#user" />
            </svg>
            <span>Users</span>
          </label>
        </div>

        <div>
          <input
            name="type"
            type="radio"
            value="type-posts"
            id="type-posts"
            onChange={handleOptionChange}
          />
          <label htmlFor="type-posts">
            <svg className="edit-pen-title">
              <use xlinkHref="#post" />
            </svg>
            <span>Posts</span>
          </label>
        </div>
        <div>
          <input
            name="type"
            type="radio"
            value="type-images"
            id="type-images"
            onChange={handleOptionChange}
          />
          <label htmlFor="type-images">
            <svg className="edit-pen-title">
              <use xlinkHref="#images" />
            </svg>
            <span>Images</span>
          </label>
        </div>
        {enableAI ?  (
        <div>
          <input 
            name="type"
            type="radio"
            value="type-special"
            id="type-special"
            onChange={handleOptionChange}
          />
          <label htmlFor="type-special">
            <svg className="edit-pen-title">
              <use xlinkHref="#special" />
            </svg>
            <span>AI</span>
          </label>
        </div>
        ) : null}
      </div>
    </form>
    </div>
  );
};

export default SearchForm;
