import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
//import { Link } from 'react-router-dom';
//import { useMsal, useAccount } from '@azure/msal-react';
//import { b2cPolicies } from './msalConfig';
import './Footer.css'; // Import the Navbar.css file

function Footer({selectedPage, subscriberLevel}) {
  const [selectedColumn, setSelectedColumn] = useState(selectedPage);
  const [currentPage, setCurrentPage] = useState(selectedPage);
  const navigate = useNavigate(); 

  const handleColumnClick = (columnId) => {
  
    setSelectedColumn(columnId);
    // Navigate based on columnId
    switch (columnId) {
      case 'A':
        setCurrentPage('A');
        navigate('/UserLandingPage');
        break;
      case 'B':
        setCurrentPage('B');
        //navigate('/pageB');
        break;
      case 'C':
        setCurrentPage('C');
        //navigate('/pageC');
        break;
      case 'D':
        setCurrentPage('D');
       // navigate('/pageD');
        break;
      case 'E':
        setCurrentPage('E');
        navigate('/VoiceChat');
        break;
      default:
        // Handle default case or do nothing
        break;
    }
  };

  useEffect(() => {
    // Only trigger handleColumnClick if selectedPage is not the same as selectedColumn
    if (currentPage !== selectedColumn) {
      handleColumnClick(currentPage);
    }
  //eslint-disable-next-line
  }, [currentPage, selectedColumn]);
  return (
    <div className="footer">
      <div className={`column ${selectedColumn === 'A' ? 'selected' : ''}`} onClick={() => handleColumnClick('A')}>HM</div>
      <div className={`column ${selectedColumn === 'B' ? 'selected' : ''}`} onClick={() => handleColumnClick('B')}>B</div>
      <div className={`column ${selectedColumn === 'C' ? 'selected' : ''}`} onClick={() => handleColumnClick('C')}>C</div>
      <div className={`column ${selectedColumn === 'D' ? 'selected' : ''}`} onClick={() => handleColumnClick('D')}>D</div>
      <div className={`column ${selectedColumn === 'E' ? 'selected' : ''}`} onClick={() => handleColumnClick('E')}>
        <svg width="64" height="64" className={selectedColumn === 'E' ? 'active-svg' : ''} >
          <use xlinkHref="#headphone" />
        </svg>
      </div>
    </div>
  );
}
export default Footer;
