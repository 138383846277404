import React, { useRef, useEffect, useState } from 'react';

const KITTAudioVisualizer = ({ audioRef, audioUrl }) => {
    const canvasRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const audioContextRef = useRef(null);
    const sourceRef = useRef(null);
    const analyserRef = useRef(null);
    const animationRef = useRef(null);


    useEffect(() => {
        if (!isPlaying) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
    },);
    useEffect(() => {
        if (!audioRef.current) return;

        const audio = audioRef.current;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const setupAudioContext = () => {
            if (!audioContextRef.current) {
                audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
            }

            if (!sourceRef.current) {
                sourceRef.current = audioContextRef.current.createMediaElementSource(audio);
            }

            if (!analyserRef.current) {
                analyserRef.current = audioContextRef.current.createAnalyser();
                sourceRef.current.connect(analyserRef.current);
                analyserRef.current.connect(audioContextRef.current.destination);
            }

            analyserRef.current.fftSize = 256;
        };

        const drawKITT = () => {
            if (!analyserRef.current) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                return;
            }
            const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);

            ctx.clearRect(0, 0, canvas.width, canvas.height);

            analyserRef.current.getByteFrequencyData(dataArray);
            const barWidth = canvas.width / 4;
            const gap = barWidth / 4;
            const centerY = canvas.height / 2;

            const drawBar = (x, height, isOuterBar = false) => {
                const maxHeight = canvas.height * 0.8;
                const fadeHeight = maxHeight * 0.2;
                const actualHeight = Math.min(height, maxHeight);

                if (isOuterBar) {
                    // Semi-transparent gradient for outer bars
                    const gradient = ctx.createLinearGradient(x, centerY - maxHeight / 2, x, centerY + maxHeight / 2);
                    gradient.addColorStop(0, 'rgba(255 0, 0, 0.3)');
                    gradient.addColorStop(fadeHeight / maxHeight, 'rgba(230, 0, 0, 0.8)');
                    gradient.addColorStop(0.5, 'rgba(180, 0, 0, 0.7)');
                    gradient.addColorStop(1 - fadeHeight / maxHeight, 'rgba(230, 0, 0, 0.8)');
                    gradient.addColorStop(1, 'rgba(255, 0, 0, 0.3)');
                    ctx.fillStyle = gradient;
                } else {
                    // Solid color for the middle bar
                    const gradient = ctx.createLinearGradient(x, centerY - maxHeight / 2, x, centerY + maxHeight / 2);
                    gradient.addColorStop(0, 'rgba(255, 0, 0, 0.3)');
                    gradient.addColorStop(fadeHeight / maxHeight, 'rgba(180, 0, 0, 1)');
                    gradient.addColorStop(0.5, 'rgba(230, 0, 0, 1)');
                    gradient.addColorStop(1 - fadeHeight / maxHeight, 'rgba(180, 0, 0,1)');
                    gradient.addColorStop(1, 'rgba(180, 0, 0, 0.3)');
                    ctx.fillStyle = gradient;
                    //ctx.fillStyle = 'rgb(255, 0, 0,1)';
                }

                ctx.fillRect(x, centerY - actualHeight / 2, barWidth - gap, actualHeight);
            };

            // Left bar (semi-transparent)
            const leftHeight = (dataArray[0] / 255) * canvas.height * 0.7;
            drawBar(gap, leftHeight, true);

            // Middle bar (solid color)
            const middleHeight = (dataArray[1] / 255) * canvas.height;
            drawBar(barWidth + gap, middleHeight);

            // Right bar (semi-transparent, mirrored)
            drawBar(2 * barWidth + gap, leftHeight, true);

            animationRef.current = requestAnimationFrame(drawKITT);
        };

        const handlePlay = () => {
            if (audioContextRef.current && audioContextRef.current.state === 'suspended') {
                audioContextRef.current.resume();
            }
            setIsPlaying(true);
            drawKITT();
        };

        const handlePause = () => {
            setIsPlaying(false);
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        };

        setupAudioContext();

        audio.addEventListener('play', handlePlay);
        audio.addEventListener('pause', handlePause);

        if (!audio.paused) {
            handlePlay();
        }

        return () => {
            audio.removeEventListener('play', handlePlay);
            audio.removeEventListener('pause', handlePause);
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, [audioRef, audioUrl]);

    return (
        <div style={{ width: '100px'}} className="w-100 max-w-sm mx-auto bg-black p-4 rounded-lg">
            <canvas
                ref={canvasRef}
                className="w-full h-48 md:h-64"
            />

        </div>
    );
};

export default KITTAudioVisualizer;