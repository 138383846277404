/* global hark, Recorder */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import './AudioTranscript.css';

function AudioTranscript({ className, onTranscription, isSpinning }) {
  const [enabled, setEnabled] = useState(false);
  const recorderRef = useRef(null);
  const audioContext = useRef(null);
  const source = useRef(null);
  const [iconEnable, setIconEnable] = useState(true);
  const [iconUrl, setIconUrl] = useState('./images/nolisten.jpg'); // Default icon
  // eslint-disable-next-line
  const [audio, setAudio] = useState(null);
  const audioRef = useRef(null);
  const apiKey = process.env.REACT_APP_API_KEY;
  const API_URL = process.env.REACT_APP_API_URL;
// eslint-disable-next-line
  const getTranscription = async (blob) => {
    let formData = new FormData();
    formData.append('file', blob, 'output.wav');
    return fetch(API_URL + '/api/Transcribe', {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': 'Bearer ' + apiKey
      }
    })
    .then(response => response.text())
    .then(data => {
      console.log('Transcribing response:', data);
      return data;
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };


  const handleStopped = useCallback(() => {
    //console.log('Handling stop recording ', enabled);
    if (enabled) {
      setIconEnable(false);
     
      console.log('Recording stopped');
      // Stop recording
       
      // Export the recorded audio to a WAV file
      recorderRef.current.exportWAV(function(blob) {
        // Handle the exported WAV file
      //let url = URL.createObjectURL(blob);
      //let link = document.createElement('a');

      //link.href = url; 
      //link.download = 'output.wav';
      //link.click();
      //setAudio(url);
      recorderRef.current.stop();
      getTranscription(blob).then((transcription) => {
        //console.log('I called getTranscription and got this:', transcription);
        onTranscription(transcription);
        setIconEnable(true);
        if (!transcription.startsWith('Stop Listening'))
          {
            //setEnabled(false);
            
          }
      });
        
      });
      
    }
  }, [enabled,onTranscription,getTranscription]);



  const handleClick = useCallback(() => {
    const state = !enabled;
    
    console.log('Enabled: ', enabled);
    if (state) {
      recorderRef.current.clear();
      recorderRef.current.record();
      console.log('Recording started');
      setIconUrl('./images/listen.jpg'); // Change to recording icon
    }
    else{
      handleStopped(state);
    }
    setEnabled(state);
  }, [enabled, handleStopped]);

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      if (!recorderRef.current){
      audioContext.current = new AudioContext();
      source.current = audioContext.current.createMediaStreamSource(stream);
      //setRecorder(new Recorder(source)); // Create a new Recorder object
      recorderRef.current = new Recorder(source.current);
      //console.log('Recorder and Audio Context Created');
      };
      
      const options = {
        smoothing: 0.8,
        interval: 75,
        threshold: -60,
        play: false,
        history: 20};

      const speechEvents = hark(stream, options);
      speechEvents.on('speaking', function() {
        console.log('speaking');
      });

      speechEvents.on('stopped_speaking', function() {
        console.log('stopped_speaking');
        if (enabled) {
          setEnabled(false);  
          setIconUrl('./images/nolisten.jpg');  
          handleClick();
        }
      });

    });


  }, [enabled, handleClick]);

 

  useEffect(() => {
    // This function is called whenever iconEnable changes
    //console.log('Icon enable changed to: ', iconEnable);
    const img = document.getElementById('headsetIcon');
    if (img) {
      img.style.opacity = iconEnable ? 1 : 0.5;
      img.onClick=iconEnable ? handleClick : null;
    }
  }, [iconEnable, handleClick]);

  const handleStop = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    
  };

  return (
    
    <div className={`${className} ${isSpinning ? 'spinning' : ''}`}>
    <img id="headsetIcon" 
         src={iconUrl} 
         alt="Headset Icon" 
         style={{width: '50px', height: '50px'}}
         onClick={iconEnable ? handleClick : null}
    />
    <button style={{ display: 'none' }} onClick={handleStop}>Stop</button>
    <audio ref={audioRef} src={audio} />
  
    </div>
  );


}

export default AudioTranscript;