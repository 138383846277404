import React from 'react';
import { Link } from 'react-router-dom';
import { useMsal, useAccount } from '@azure/msal-react';
import { b2cPolicies } from './msalConfig';
import './Navbar.css'; // Import the Navbar.css file

function Navbar() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {})

  const handleSignOut = () => {
    instance.logout();
  };

  const handleSignIn = () => {
    //instance.loginRedirect(); // or instance.loginRedirect();
    // instance.loginRedirect({ authority: msalConfig.authorities.signUpSignIn.authority }); 
    const signInRequest = b2cPolicies.authorities.signUpSignIn
    instance.loginRedirect(signInRequest);
  };

  const handleProfile = () => {
    const editProfileRequest = {
      authority: b2cPolicies.authorities.editProfile.authority,
      loginHint: account.username
    };
    instance.loginRedirect(editProfileRequest);
  };

  //   {account && <button onClick={handleSignOut}>Sign Out</button>}
  return (
    <>

      <div className="header">
        <div className="header-menu">
        <label className="hamburger-menu">
          <input type="checkbox" className="Checkbox" />
        </label>
        <aside className="sidebar">
          <nav className="navbar2">
            <ul className="navbar-nav">
            <li className="nav-title">
                 MENU
                </li>
              {account?.name ? (
                <>
                  <li className="nav-item">
                    <Link to="" onClick={handleProfile} className="nav-link">{account.name}</Link>
                  </li>
                  <li className="nav-item2">
                    <Link to="" onClick={handleSignOut} className="nav-link">Sign Out</Link>
                  </li>
                </>
              ) : (
                <li className="nav-item2">
                  <Link to="" onClick={handleSignIn} className="nav-link">Sign In / Sign Up</Link>
                </li>
              )}
              
              <li className="nav-item">
                <Link to="/" className="nav-link">Home</Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link">About Us</Link>
              </li>
              <li className="nav-item">
                <Link to="/PrivacyPolicy" className="nav-link">Privacy Policy</Link>
              </li>
              <li className="nav-item">
                <Link to="/CookiePolicy" className="nav-link">Cookie Policy</Link>
              </li>
            </ul>
          </nav>
        </aside>
      </div>
      <div className="header-banner">
        
        RECIPE GURU
        </div>
        </div>
       </>
      );
}

export default Navbar;