import React, { useEffect, useState } from 'react';

function UserProfile() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    fetch('/.auth/me')
      .then((response) => response.json())
      .then((data) => {
        const { clientPrincipal } = data;
        setUser(clientPrincipal);
      })
      .catch((error) => console.error('Error fetching user:', error));
  }, []);

  if (!user) {
    return <div>Loading...</div>;
  }

  return <div>Welcome, {user.userDetails}!</div>;
}

export default UserProfile;