import React, { useEffect } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import './Homepage.css';
import Navbar from './Components/Navbar';

function Homepage() {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const navigate = useNavigate();

  useEffect(() => {
    if (account) {
      navigate('/UserLandingPage');
    }
  }, [account, navigate]);



  return (
    <div className="Homepage">
      <Navbar />
      
    </div>
  );
}

export default Homepage;