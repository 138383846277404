import React, { useRef, useEffect, useState } from 'react';

function ResponsiveKITT() {
    const canvasRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(true);
   

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let animationFrameId;

        const handleResize = () => {
            canvas.width = canvas.offsetWidth;
            canvas.height = canvas.offsetHeight;
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        const drawKITT = () => {
            const dataArray = [Math.floor(Math.random() * 256), Math.floor(Math.random() * 256)];

            ctx.clearRect(0, 0, canvas.width, canvas.height);

            const barWidth = canvas.width / 4;
            const gap = barWidth / 4;
            const centerY = canvas.height / 2;

            const drawBar = (x, height, isOuterBar = false) => {
                const maxHeight = canvas.height * 0.8;
                const fadeHeight = maxHeight * 0.2;
                const actualHeight = Math.min(height, maxHeight);

                const gradient = ctx.createLinearGradient(x, centerY - maxHeight / 2, x, centerY + maxHeight / 2);
                if (isOuterBar) {
                    gradient.addColorStop(0, 'rgba(255, 0, 0, 0.3)');
                    gradient.addColorStop(fadeHeight / maxHeight, 'rgba(230, 0, 0, 0.8)');
                    gradient.addColorStop(0.5, 'rgba(180, 0, 0, 0.7)');
                    gradient.addColorStop(1 - fadeHeight / maxHeight, 'rgba(230, 0, 0, 0.8)');
                    gradient.addColorStop(1, 'rgba(255, 0, 0, 0.3)');
                } else {
                    gradient.addColorStop(0, 'rgba(255, 0, 0, 0.3)');
                    gradient.addColorStop(fadeHeight / maxHeight, 'rgba(180, 0, 0, 1)');
                    gradient.addColorStop(0.5, 'rgba(230, 0, 0, 1)');
                    gradient.addColorStop(1 - fadeHeight / maxHeight, 'rgba(180, 0, 0, 1)');
                    gradient.addColorStop(1, 'rgba(180, 0, 0, 0.3)');
                }
                ctx.fillStyle = gradient;

                ctx.fillRect(x, centerY - actualHeight / 2, barWidth - gap, actualHeight);
            };

            const leftHeight = (dataArray[0] / 255) * canvas.height * 0.7;
            drawBar(gap, leftHeight, true);

            const middleHeight = (dataArray[1] / 255) * canvas.height;
            drawBar(barWidth + gap, middleHeight);

            drawBar(2 * barWidth + gap, leftHeight, true);

            animationFrameId = requestAnimationFrame(drawKITT);
        };

        if (isPlaying) {
            drawKITT();
        } else {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
            cancelAnimationFrame(animationFrameId);
        };
    }, [isPlaying]);

    const togglePlay = () => {
        setIsPlaying(!isPlaying);
    };
    
    return (
        <div className="w-full max-w-sm mx-auto bg-black p-4 rounded-lg">
            <canvas 
                ref={canvasRef} 
                className="w-full h-48 md:h-64"
            />
            <button 
                onClick={togglePlay}
                className="mt-4 w-full py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
            >
                {isPlaying ? 'Stop KITT' : 'Start KITT'}
            </button>
        </div>
    );
}

export default ResponsiveKITT;