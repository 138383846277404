const AuthClientID = process.env.REACT_APP_AuthClientID;
export const b2cPolicies = {
  names: {
      signUpSignIn: "B2C_1_SUSI1",
      editProfile: "B2C_1_EditProfile"
  },
  authorities: {
      signUpSignIn: {
          authority: "https://login.recipeguru.ai/users.recipeguru.ai/b2c_1_susi1",
      },
      editProfile: {
          authority: "https://login.recipeguru.ai/users.recipeguru.ai/b2c_1_editprofile"
      }
  },
  authorityDomain: "login.recipeguru.ai"
}

export const msalConfig = {
  auth: {
    clientId: AuthClientID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: window.location.protocol + '//' +  window.location.host +  window.location.pathname,                                                                            
    navigateToLoginRequestUrl: true
  },
};

export default msalConfig;