

function AskQuestion( ThreadID, Question, onResult ) {
    const apiKey = process.env.REACT_APP_API_KEY;
    const API_URL = process.env.REACT_APP_API_URL;
    const assistantID = "asst_uC5S2nPpB3Zih6HUXfQJR4a7";
    console.log('Asking question:', Question);
    //console.log(JSON.stringify({ ThreadID, assistantID, Question }));
    fetch(API_URL + '/api/Assistant', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + apiKey
        },
    
        body: JSON.stringify({ ThreadID , assistantID, Question })
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('API request failed');
            }
            return response.text();
        })
        .then(text => {
            const data = JSON.parse(text);
            onResult(data);
            /*
            // Check if the parsed value is an array
            if (Array.isArray(array) && array.length > 0) {
                // Pass the first item in the array back to the parent component
                onResult(array[0]);
            } else {
                throw new Error('Response is not an array or is empty');

            }
            */
            // Pass the response back to the parent component

        })
        .catch(error => {
            console.error('Error:', error);
            // Optionally, set audioElement.src to a default audio file or message
            // audioElement.src = 'default-audio-file-url';
        });
}

export default AskQuestion;