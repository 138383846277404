import React, { useEffect, useState } from 'react';
import { useIsAuthenticated,useMsal, useAccount } from '@azure/msal-react';
//import { useNavigate } from 'react-router-dom';
import GetProfile from './Components/ProfileAttributes'
import './Subscribe.css';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';

function Subscribe({ API_URL }) {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(accounts[0] || {});
  //const navigate = useNavigate();
  const [subscriberLevel, setSubscriberLevel] = useState(0);

  useEffect(() => {
    const fetchProfile = async () => {
      if (isAuthenticated) {
        try {
          const profile = await GetProfile(instance, account, API_URL);
          setSubscriberLevel(profile);
        } catch (error) {
          console.error('Error fetching profile:', error);
          // Handle the error appropriately
        }
      }
    };
  
    fetchProfile();
  }, [account, isAuthenticated, instance, API_URL]);


  return (
    <div className="Subscribe">
     
      <Navbar />
      
        <div className="SubscribeHeader">
        <p>Premium Feature</p>
        <p>While we make every effort to keep the main product free, some feaures are are only available to subscribers. 
            This is mostly AI features as they are incredibly expensive to operate and we are a family business.</p>
        <p>Subscribing will unlock the following AI features:
            <ul>
                <li>AI Search - Ai will help search </li>
                <li>AI Voice Chat - Recipe Suggestions, wine suggestions, recipe adjustments</li>
                <li>AI Suggest from Photo - Find recipes containing item in photo, identify ingredients</li>
            </ul>
        </p>
        <p>Watch this video showing these features in action</p>
      </div>
      <div className="SubscribeBody">
      <iframe width="100%" class="Youtube" src="https://www.youtube.com/embed/pj_mwXlOVEo?si=yunNISR4d74E9CWI" title="RecipeGuru" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div> 
      <Footer selectedPage='E' subscriberLevel={subscriberLevel}/>
    </div>
  );
}


export default Subscribe;