
import React, { useState, useEffect,useCallback } from 'react';
import PageFlip from 'react-pageflip';
import * as signalR from '@microsoft/signalr';
import backgroundImage from './images/recipeguru.png'; // Import the image
import { v4 as uuidv4 } from 'uuid';
import QRCode from 'qrcode.react';
import './RecipeBook.css'; // Import the App.css file
import UserProfile from './Profile';
import {getCookie, setCookie} from './Components/cookies.js';
//import getRecipes from './Components/recipes.js';
import * as constants from './Components/constants.js';
import { useNavigate } from 'react-router-dom';


function RecipeBook({API_URL}) {
  const [ setLog] = useState('');
  const [isLoggedIn] = useState(false);
  const navigate = useNavigate();
 
  //const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [regConnection, setRegConnection] = useState(null);
  const [recConnection, setRecConnection] = useState(null);
  const [isActivation, setIsActivation] = useState(false);
  // eslint-disable-next-line
  const [isDebug, setIsDebug] = useState(false);
  const [deviceName, setDeviceName] = useState(getCookie('deviceName'));
  const [deviceId, setDeviceId] = useState(getCookie('deviceId')); // Add this line
  const [connectionStatus, setConnectionStatus] = useState('Disconnected'); // Add this line
  const [currentRecipe, setCurrentRecipe] = useState(null);
  const [currentFrame, setCurrentFrame] = useState(null);
  
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const authHubKey = process.env.REACT_APP_AUTH_KEY;
  const apiKey = process.env.REACT_APP_API_KEY; // Replace with your API key
  const getRecipes = useCallback(() => {
    return fetch(API_URL + `/api/Recipes`, {
      headers: { 'Authorization': `${apiKey}` }
    })
      .then(response => { return response.json() })
      .then(recipes => {
        return recipes; // Return the fetched recipe
      })
      .catch(err => {
        throw err; // Throw the error so it can be caught by the caller
      });
  }, [API_URL, apiKey]); // Include any dependencies of getRecipes here
  //const id = getCookie('deviceId');
  //const deviceName = getCookie('deviceName');
 
  //const API_URL = "https://recipeguru.azurewebsites.net";
  //API_URL = "https://localhost:7001";
  //const REG_URL = "http://172.31.2.1:3000";
  const REG_URL = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;

  useEffect(() => {    //Registration Hub Setup
    let id = deviceId;
    if (!id) {
      id = uuidv4();
      setCookie('deviceId', id, 90);
      setDeviceId(id);
      if (isDebug) setLog(prevLog => `${prevLog}\n${'device has a new id: ' + id}`);
    }
    
    if (deviceName) return;
    
    const connection = new signalR.HubConnectionBuilder()
    .withUrl(API_URL + '/registerhub',
      {
        accessTokenFactory: () => authHubKey,
        transportType: signalR.HttpTransportType.LongPolling
      })
    .build()
    setRegConnection(connection);
    connection.on('DeviceRegistered', (key,deviceName) => {
      const message = `registration ${key} was received for ${deviceName}`;
      setDeviceName(deviceName);
      setCookie('deviceName', deviceName, 90);
      if(recConnection){
        setLog(prevLog => `${prevLog}\nStarting RecipeHub`);
        startRecipeHub();
      }
      else{
        window.location.reload();
      }
      if (isDebug) setLog(prevLog => `${prevLog}\n${message}`);
    });
    connection.start()
      .then(() => {
        connection.invoke('JoinGroup', id)
          .then(() => {
            setIsActivation(true);
            if (isDebug) setLog(prevLog => `${prevLog}\nJoined as ${getCookie('deviceId')}`);
          })
          .catch(err => {
           if (isDebug) setLog(prevLog => `${prevLog}\n${err.toString()}`);
          });
      })
      .catch(err => {
         if (isDebug) setLog(prevLog => `${prevLog}\n${err.toString()}`);
 
      });
    // Add this to handle connection close
    connection.onclose(err => {
      setIsActivation(false);
      if (err) {
        if (isDebug) setLog(prevLog => `${prevLog}\n${err.toString()}`);
      }
    });
  return () => {
    connection.stop();
  };
  // eslint-disable-next-line
  }, []);


  useEffect(() => {    //Recipe Hub Setup
    //let id = getCookie('deviceId');
    
    if (!deviceName) {
      return;
    }
    // Create a new connection
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(API_URL + '/recipehub',
        {
          accessTokenFactory: () => apiKey,
          transportType: signalR.HttpTransportType.LongPolling

        })
      .build()
      setRecConnection(connection);
    // Define a method to handle the DisplayRecipe call
    // We need to dump the ID and get array or just get all
    connection.on('DisplayRecipe', (id) => {
      setCurrentFrame(null);
      getRecipes()
        .then(recipes => {
            setCurrentRecipe(recipes);
         })
        .catch(err => {
            // Handle the error
        });
      const message = `Recipe with id ${id} was displayed`;
      if (isDebug) setLog(prevLog => `${prevLog}\n${message}`);
    });
    connection.on('DisplayFrame', (id) => {
      id = id.replaceAll('%2F', '/');
      const message = `Resource with src ${id} was displayed`;
      if (isDebug) setLog(prevLog => `${prevLog}\n${message}`);
      setCurrentRecipe(null);
      setCurrentFrame(id);
    });



    connection.start()
    .then(() => {
      setConnectionStatus('Connected'); // Add this line
      setLog(prevLog => `${prevLog}\nReconnected to /recipeHub`);
      connection.invoke('JoinGroup', deviceName)
        .then(() => setLog(prevLog => `${prevLog}/${deviceName}`))
        .then(() => {
          setConnectionStatus('Connected!');
         if (isDebug) setLog(prevLog => `${prevLog}/${deviceName}`);
        })
        .catch(err => {
           if (isDebug) setLog(prevLog => `${prevLog}\n${err.toString()}`);
       });
  
      })
      .catch(err => {
        setConnectionStatus('Disconnected'); 
        if (isDebug) setLog(prevLog => `${prevLog}\n${err.toString()}`);
      });



    
    // Add this to handle connection close
    connection.onclose(err => {
      setConnectionStatus('Disconnected') ;
      if (err) {
        if (isDebug) setLog(prevLog => `${prevLog}\n${err.toString()}`);
        setConnectionStatus('Disconnected');
      }
    });
   
    return () => {
      connection.stop();
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getRecipes()
      .then(recipes => {
        setCurrentRecipe(recipes);
      })
      .catch(err => {
        // Handle the error
      });
  }, [getRecipes]); // getRecipes now has a stable reference
  
  return (  // Main Viewer container switches between views
    <div className="RecipeBook" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className={`panel ${isPanelOpen ? 'open' : ''}`}>
      <p>Status: {connectionStatus} {deviceName && <>as <b>{deviceName}</b></>}</p>
      <p>Logged in: {isLoggedIn} <UserProfile /> </p>
      {deviceName && (
        <button className="forget-button" onClick={() => {
                      
          if (recConnection) {
            recConnection.invoke('LeaveGroup', deviceName)
              .then(() => {
                recConnection.stop()
                  .then(() => setLog(prevLog => `${prevLog}\nDisconnected from /recipeHub`))
                  .then(() => { 
                      setCookie('deviceName', null);
                      setDeviceName(null);
                      if (regConnection){
                        regConnection.start()
                          .then(() => setLog(prevLog => `${prevLog}\nConnected to /registrationHub`))
                          .catch(err =>  window.location.reload());}
                      else {window.location.reload()}
                  })
                  .catch(err => setLog(prevLog => `${prevLog}\n${err.toString()}`));
              })
              .catch(err => setLog(prevLog => window.location.reload()));
          }
          

        }}>
          FORGET
        </button>
      )}<p> </p>
            {constants.log}
    </div>
    <div className={`status-icon ${connectionStatus === 'Connected!' ? 'connected' : 'disconnected'}`} onClick={togglePanel}></div>
       
      {!deviceName ? (
        // The user is not logged in, render the login button and the QR code
        renderLoggedOut()
      ) : (
        <>

          {currentRecipe ? (
            <Recipe recipes={currentRecipe} onClose={() => setCurrentRecipe(null)} />
          ) : (
            <pre></pre>
          )}
          {currentFrame ? (
            <Frame src={currentFrame} onClose={() => setCurrentFrame(null)} />
          ) : (
            null
          )}
        </>
      )}
    </div>
  );
  

  /*
  recConnection.start()
          .then(() => setLog(prevLog => `${prevLog}\nReconnected to /recipeHub`))
          .catch(err => setLog(prevLog => `${prevLog}\n${err.toString()}`));
  */
 // An attempt to reconnect and re-enter the group
function startRecipeHub(){
  //if(recConnection)
  //{
  recConnection.start()
  .then(() => {
    setConnectionStatus('Connected'); // Add this line
    setLog(prevLog => `${prevLog}\nReconnected to /recipeHub`);
    recConnection.invoke('JoinGroup', deviceName)
      //.then(() => setLog(prevLog => `${prevLog}\nJoined as ${deviceName}`))
      .then(() => {
        setConnectionStatus('Connected!');
       if (isDebug) setLog(prevLog => `${prevLog}/${deviceName}`);
      })
      .catch(err => {
         if (isDebug) setLog(prevLog => `${prevLog}\n${err.toString()}`);
     });

    })
    .catch(err => {
      setConnectionStatus('Disconnected'); 
      if (isDebug) setLog(prevLog => `${prevLog}\n${err.toString()}`);
    });

 // };
}


  function renderLoggedOut() {   // Main registration content
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', transform: 'translateY(-150px)' }}>
        <div style={{ backgroundColor: '#f0f0f0', borderRadius: '10px', padding: '20px', width: '300px', textAlign: 'center' }}>
          <h2>Scan to connect</h2>
          
          {isActivation ? (
            <>
            <QRCode value={REG_URL + '/register?key=' + getCookie('deviceId')} size={128} /> 
            <p>Open your phone's camera and point it at the QR code</p>
            </>
            
          ) : (null)}
         
          <button
            onClick={() => navigate('/register?key=' + getCookie('deviceId') + '&redirect=RecipeBook')}
            style={{
              backgroundColor: '#ffffff',
              color: 'black',
              border: 'none',
              borderRadius: '2px',
              padding: '10px 20px',
              fontSize: '14px',
              fontWeight: 'bold',
              cursor: 'pointer',
              marginTop: '20px',
            }}
          >
            
            REGISTER DEVICE
          </button>
        </div>
      </div>
    );
  }


  function togglePanel() {    //Panel helper 
    setIsPanelOpen(prevIsPanelOpen => !prevIsPanelOpen);
  }
  


  function Recipe({ recipes, onClose }) {   // This is the recipe Panel
   // const placeholderImage = 'https://demofree.sirv.com/nope-not-here.jpg';

    return (
      <PageFlip>
        {recipes.map((recipe, index) => (
          <PageFlip.Page key={index}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <div style={{ position: 'relative', display: 'flex', border: '1px solid black', padding: '20px', borderRadius: '10px', width: '80%', background: '#fff', boxShadow: '-7px 7px 10px rgba(0, 0, 0, 0.6)' }}>
                <button onClick={onClose} style={{ position: 'absolute', top: '10px', right: '10px' }}>X</button>
                <div style={{ flex: '1', paddingRight: '20px', borderRight: '1px solid black' }}>
                  <h1>{recipe.name}</h1>
                  <p>{recipe.description}</p>
                  <h2>Ingredients (Serves {recipe.serves})</h2>
                  <ul>
                    {recipe.ingredients.map((ingredient, index) => (
                      <li key={index}>{ingredient.name}: {ingredient.quantity}</li>
                    ))}
                  </ul>
                </div>
                <div style={{ flex: '1', paddingLeft: '20px' }}>
                  <h2>Steps</h2>
                  <ol>
                    {recipe.instructions.map((instruction, index) => (
                      <li key={index}>{instruction.description}</li>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
          </PageFlip.Page>
        ))}
      </PageFlip>
    );
  }


  function Frame({ src, onClose }) {  //This is the iFrame Panel
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div style={{ position: 'relative', border: '1px solid black', padding: '20px', borderRadius: '10px', width: '80%', background: '#fff', boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.25)' }}>
          <button onClick={onClose} style={{ position: 'absolute', top: '10px', right: '10px' }}>X</button>
          <iframe src={src} style={{ width: '100%', height: '80vh' }} title="Frame" />
        </div>
      </div>
    );
  }




}
export default RecipeBook;
