import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import backgroundImage from './images/recipeguru.png'; // Import the image
import { useMsal, useAccount } from '@azure/msal-react';
import './Register.css'; // Import the App.css file

function Register({ API_URL, REG_URL }) {
  const [log, setLog] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [deviceName, setDeviceName] = useState('');
  const [ID, setID] = useState('');
  const { instance, accounts } = useMsal();
  //const apiKey = process.env.REACT_APP_API_KEY;
  const account = useAccount(accounts[0] || {})
  const params = new URLSearchParams(location.search);
  const redirect = params.get('redirect');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const key = params.get('key');
    

    //setLog(prevLog => `${prevLog}\n${'Handling Key'}${key}`);
    if (!key) {
      return (
        <div className="Homepage" style={{ backgroundImage: `url(${backgroundImage})` }}>
          <p> so no key</p>
        </div>
      );
    }
    setID(key);

    // Handle the key here. For example, you might want to send a request to your server to register the key.

    // After handling the key, you can redirect to another page

  }, [location, navigate]);

  const handleInputChange = (event) => {
    //setLog(prevLog => `${prevLog}\n${'Handling event ' + event.target.value}`);
    setDeviceName(event.target.value);
    setCookie('deviceName', event.target.value, 90);
  };

  const handleSubmit = async () => {
    //setLog(prevLog => `${prevLog}\n${'Handling Submition' + ID + ' ' + deviceName}`);
    //var name = getCookie('deviceName');
    //setLog(prevLog => `${prevLog}\n${'Handling Name:  ' + name}\n`);
    
    await registerDeviceName(instance,account, ID, getCookie('deviceName'));
    if (redirect) {
      navigate(`/${redirect}`);
      return;
    }
    
    //navigate('/Devices');
    // Handle the device name here
    // For example, you might want to send a request to your server to register the device name
  };

  return (
    <div className="Register" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <p>{API_URL}</p>
      <p>{log}</p>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', transform: 'translateY(-150px)' }}>
        <div style={{ backgroundColor: '#f0f0f0', borderRadius: '10px', padding: '20px', width: '400px', textAlign: 'center' }}>
          <h2>Enter Device Name</h2>
          <input type="text" value={deviceName} onChange={handleInputChange} style={{ width: '80%', padding: '10px', marginBottom: '20px', fontWeight: 'bold', fontSize: '18px' }} />
          <button onClick={handleSubmit} style={{
            backgroundColor: '#4CAF50', /* Green */
            border: 'none',
            color: 'white',
            padding: '15px 32px',
            textAlign: 'center',
            textDecoration: 'none',
            display: 'inline-block',
            fontSize: '16px',
            margin: '4px 2px',
            cursor: 'pointer',
            borderRadius: '12px',
            transitionDuration: '0.4s',
          }}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#45a049'}
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#4CAF50'}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )




  async function registerDeviceName(instance,account, ID, device) {
    const message = `Setting client ${ID} to ${device} at server ${REG_URL}/api/Authenticator`;
    //const account = instance.getActiveAccount();
    

    if (!account) {
      throw new Error('No active account');
    }
    const response = await instance.acquireTokenSilent({
      scopes: ['da164f41-d248-48b5-a2bc-0c4470623fb9'], // Replace with your API scopes
      account: account,
    });

    setLog(prevLog => `${prevLog}\n${message}`);
    setLog(prevLog => `${prevLog}\n${response.accessToken}\n\n`);
    fetch(REG_URL + `/api/Authenticator`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${response.accessToken}`,
        'ID': ID,
        'deviceName': device
      },
    }).then(response => {
      if (!response.ok) {
        setLog(prevLog => `${prevLog}\n${response.status}`);
        throw new Error('Network response was not ok');
      }
      //setLog(prevLog => `${prevLog}\n${response.text()}`);
      return response.text();
    }).catch(error => {
      setLog(prevLog => `${prevLog}\n${error.toString()}`);
      console.error('There has been a problem with your fetch operation:', error);
    });
  }

  /*
  function registerDeviceName(ID, device) {
    //const message = `Setting client ${ID} to ${device} at server ${REG_URL}/api/Authenticator`;
    //setLog(prevLog => `${prevLog}\n${message}`);
    fetch(REG_URL + `/api/Authenticator`, {
      method: 'POST', // Specify the method
      headers: {
        'Authorization': apiKey,
        'ID': ID,
        'deviceName': device
      },
    })
      //.then(response => {return response.json()})
      .catch(err => setLog(prevLog => `${prevLog}\n${'22' + err.toString()}`)); // Log any errors
  }
  */


  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  // Function to set a cookie
  function setCookie(name, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `; expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value || ""}${expires}; path=/`;
  }

};
export default Register;