import  HTMLFlipBook from 'react-pageflip';
import React, { useState, useEffect,useCallback } from 'react';
import backgroundImage from './images/recipeguru.png'; // Import the image
import './RecipeBookAlpha.css'; // Import the App.css file
import UserProfile from './Profile';
import { getCookie} from './Components/cookies';

const PageCover = React.forwardRef((props, ref) => {
  return (
    <div className="cover" ref={ref} data-density="hard">
      <div>
        <h2>{props.children}</h2>
      </div>
    </div>
  );
});

const Page = React.forwardRef((props, ref) => {
  return (
    <div className="page" ref={ref}>
      <p>{props.children}</p>
      <p>{props.number}</p>
    </div>
  );
});




function RecipeBookAlpha({API_URL}) {
  const [isLoggedIn] = useState(false);
  const [deviceName] = useState(getCookie('deviceName'));
  const [deviceId] = useState(getCookie('deviceId')); // Add this line
  const [currentRecipe, setCurrentRecipe] = useState(null);
 // const [currentFrame, setCurrentFrame] = useState(null);
  
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  //const authHubKey = process.env.REACT_APP_AUTH_KEY;
  const apiKey = process.env.REACT_APP_API_KEY; // Replace with your API key

  /*function getRecipes() {   // Fetches a recipe from Cosmos from RecipeID
    //const message = `Recipe with id ${id} is being fetched`;
    //setLog(prevLog => `${prevLog}\n${message}`);
    
    return fetch(API_URL + `/api/Recipes`, {
      headers: { 'Authorization': `${apiKey}` }
    })
      .then(response => { return response.json() })
      .then(recipes => {
        //setLog(prevLog => `${prevLog}\n${recipe.description} was found`);
        return recipes; // Return the fetched recipe
      })
      .catch(err => {
        //setLog(prevLog => `${prevLog}\n${err.toString()}`);
        throw err; // Throw the error so it can be caught by the caller
      });
  }
*/
const getRecipes = useCallback(() => {   // Fetches a recipe from Cosmos from RecipeID
  //const message = `Recipe with id ${id} is being fetched`;
  //setLog(prevLog => `${prevLog}\n${message}`);
  
  return fetch(API_URL + `/api/Recipes`, {
    headers: { 'Authorization': `${apiKey}` }
  })
    .then(response => { return response.json() })
    .then(recipes => {
      //setLog(prevLog => `${prevLog}\n${recipe.description} was found`);
      return recipes; // Return the fetched recipe
    })
    .catch(err => {
      //setLog(prevLog => `${prevLog}\n${err.toString()}`);
      throw err; // Throw the error so it can be caught by the caller
    });
}, [API_URL, apiKey]); // Add any dependencies here

  useEffect(() => {
    getRecipes()
      .then(recipes => {
        setCurrentRecipe(recipes);
      })
      .catch(err => {
        // Handle the error
      });
  }, [getRecipes]); // getRecipes now has a stable reference
  
  return (  // Main Viewer container switches between views
    <div className="RecipeBook" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className={`panel ${isPanelOpen ? 'open' : ''}`}>
      <p> {deviceId && <>as <b>{deviceName}</b></>}</p>
      <p>Logged in: {isLoggedIn} <UserProfile /> </p>
      {deviceName && (
        <button className="forget-button" onClick={() => {
                      

          

        }}>
          FORGET
        </button>
      )}<p> </p>
          
    </div>
    <div className={`status-icon 'Connected!'`} onClick={togglePanel}></div>
       
      {!deviceName ? (
        // The user is not logged in, render the login button and the QR code
        renderLoggedOut()
      ) : (
        <>

          {currentRecipe ? (
            <Recipe recipes={currentRecipe} onClose={() => setCurrentRecipe(null)} />
          ) : (
            <pre></pre>
          )}

        </>
      )}
    </div>
  );
  



  function renderLoggedOut() {   // Main registration content
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', transform: 'translateY(-150px)' }}>
        <div style={{ backgroundColor: '#f0f0f0', borderRadius: '10px', padding: '20px', width: '300px', textAlign: 'center' }}>
          <h2>Scan to connect</h2>
          
         
         
          <button
              onClick={() => {}}
            style={{
              backgroundColor: '#ffffff',
              color: 'black',
              border: 'none',
              borderRadius: '2px',
              padding: '10px 20px',
              fontSize: '14px',
              fontWeight: 'bold',
              cursor: 'pointer',
              marginTop: '20px',
            }}
          >
            
            REGISTER DEVICE
          </button>
        </div>
      </div>
    );
  }


  function togglePanel() {    //Panel helper 
    setIsPanelOpen(prevIsPanelOpen => !prevIsPanelOpen);
  }
  


  function Recipe({ recipes, onClose }) {
    const recipes2 = recipes.concat(recipes)
    return (
      <div>
        <h2>test</h2>
        <HTMLFlipBook
        width={550}
        height={650}
        minWidth={315}
        maxWidth={1400}
        minHeight={420}
        maxHeight={1650}
        showCover={true}
        className="album-web">
        <PageCover>Kimmies Recipes</PageCover>
        

        {recipes2.map((recipe, index) => {   
          if (index % 2 === 0){
           return (
            <Page number={index}> 




               
                  
              <div key={index*2} style={{ flex: '1', paddingRight: '20px', borderRight: '1px solid black' }}>
                  <h1>{recipe.name}</h1>
                  <p>{recipe.description}</p>
                  <h2>Ingredients (Serves {recipe.serves})</h2>
                  <ul>
                    {recipe.ingredients.map((ingredient, index) => (
                      <li key={index}>{ingredient.name}: {ingredient.quantity}</li>
                    ))}
                  </ul>
                </div> 
            </Page>
                  );
          } else {
            return (
              <Page number={index}>
              <div key={(index*2)+1} style={{ flex: '1', paddingRight: '20px', borderRight: '1px solid black' }}>
                  <h2>Steps</h2>
                  <ol>
                    {recipe.instructions.map((instruction, index) => (
                      <li key={index}>{instruction.description}</li>
                    ))}
                  </ol>
                </div>
            </Page>
            );


          }
  })}
      
        
         
         <PageCover></PageCover>
         <PageCover>see you</PageCover>
        </HTMLFlipBook>
       
      </div>
    );
  }

      /*
      <PageFlip>
        {recipes.map((recipe, index) => (
          <PageFlip.Page key={index}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <div style={{ position: 'relative', display: 'flex', border: '1px solid black', padding: '20px', borderRadius: '10px', width: '80%', background: '#fff', boxShadow: '-7px 7px 10px rgba(0, 0, 0, 0.6)' }}>
                <button onClick={onClose} style={{ position: 'absolute', top: '10px', right: '10px' }}>X</button>
                <div style={{ flex: '1', paddingRight: '20px', borderRight: '1px solid black' }}>
                  <h1>{recipe.name}</h1>
                  <p>{recipe.description}</p>
                  <h2>Ingredients (Serves {recipe.serves})</h2>
                  <ul>
                    {recipe.ingredients.map((ingredient, index) => (
                      <li key={index}>{ingredient.name}: {ingredient.quantity}</li>
                    ))}
                  </ul>
                </div>
                <div style={{ flex: '1', paddingLeft: '20px' }}>
                  <h2>Steps</h2>
                  <ol>
                    {recipe.instructions.map((instruction, index) => (
                      <li key={index}>{instruction.description}</li>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
          </PageFlip.Page>
        ))}
      </PageFlip>
      */
 

/*
  function Frame({ src, onClose }) {  //This is the iFrame Panel
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div style={{ position: 'relative', border: '1px solid black', padding: '20px', borderRadius: '10px', width: '80%', background: '#fff', boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.25)' }}>
          <button onClick={onClose} style={{ position: 'absolute', top: '10px', right: '10px' }}>X</button>
          <iframe src={src} style={{ width: '100%', height: '80vh' }} title="Frame" />
        </div>
      </div>
    );
  }
*/



}
export default RecipeBookAlpha;

